import React, { useContext, useState } from 'react'
import { DataContext } from '../../../../../../context/DataContext'
import { Modal } from '../../../../../../components/Modal/Modal'
import { useModal } from '../../../../../../hooks/useModal'
import { dashedFormatDateDDMMYYYY, dashedFormatDateYYYYMMDD, downloadFilesWithJSZip } from '../../../../../../utils/functions'
import { formatDateToDDMMYYYY, handlefinishProcess, handleNewStartDate, handleSaveNewDate } from './DocumentationProcessFunctions'
import { ScriptContext } from '../../../../../../context/ScriptsContext'
import orangeArrowIcon from '../../../../../../assets/icons/icon_flecha_naranja.svg'
import downloadIcon from '../../../../../../assets/icons/icon_descarga_naranja.svg'

import './DocumentationProcess.css'
import { resendEmail } from '../../../../../../utils/userQueries'
import { AreYourSureModal } from '../../../../../../components/AreYouSureModal/AreYourSureModal'

export const DocumentationProcess = ({ isActive, idToken, setActiveViews, demandSelected, requestData }) => {
  const { scriptsSave } = useContext(ScriptContext)
  if (!isActive) return null
  const [showModal, handleShow] = useModal()
  const [showModal2, handleShow2, handleClose2] = useModal()
  const [showRUSureModal, handleShowRUSure, handleCloseRUSure] = useModal()

  const dataContext = useContext(DataContext)
  const [radicateNum, setRadicateNum] = useState(null)
  const [calendarActive, setCalendarActive] = useState(false)
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [isRequesting, setIsRequesting] = useState(false)
  const [newStatusCode, setNewStatusCode] = useState(0)
  const [newStartDate, setNewStartDate] = useState(dashedFormatDateYYYYMMDD(demandSelected.statusDate))

  const { demandId, instanceHistory } = demandSelected
  const handleResendEmail = async () => {
    const requestData = {
      idDemand: demandId
    }
    const responseResendEmail = await resendEmail({ requestData, idToken, dataContext })
    if (responseResendEmail.response) {
      handleShow2()
    }
  }

  const handleChangeProcessStatus = ({ statusCode }) => {
    setNewStatusCode(statusCode)
    handleShowRUSure()
  }
  return (
    <section id='DocumentationProcess'>
        <div className='DocumentationProcessTableBox'>
            <table className='DocumentationProcessTable'>
                <thead>
                    <tr>
                      <th className='DocumentationProcessTitle'>
                        {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.column1Name : ''}
                      </th>
                      <th className='DocumentationProcessTitle'>
                        {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.column3Name : ''}
                      </th>
                      <th className='DocumentationProcessTitle'>
                        {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.column2Name : ''}
                      </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='DocumentationProcessRow'>
                      <td className='DocumentationProcessText'>
                        {instanceHistory && instanceHistory.length
                          ? <button
                              className='DocumentationProcessDropdown'
                              onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
                            >
                              <p>{demandSelected.name}</p>
                              <div className='DocumentationDropdownArrow'>
                                <img
                                  src={orangeArrowIcon}
                                  className={dropdownIsOpen ? 'dropdownOpen' : 'dropdownClosed'}
                                  alt="orangeArrowIcon" />
                              </div>
                            </button>
                          : <p>{demandSelected.name}</p>
                        }
                      </td>
                      <td className='DocumentationProcessText'>
                        {!calendarActive
                          ? (formatDateToDDMMYYYY(newStartDate))
                          : (<input
                              type='date'
                              className='DocumentationProcessCalendarInput'
                              onChange={(e) => handleNewStartDate(e, setNewStartDate)}
                              onBlur={() => handleSaveNewDate(setCalendarActive, demandSelected, newStartDate, dataContext, idToken)}
                              onKeyDown={(e) => e.key === 'Enter' && handleSaveNewDate(setCalendarActive, demandSelected, newStartDate, dataContext, idToken)}
                              value={newStartDate}
                            />)
                        }
                      </td>
                      <td className='DocumentationProcessText'>{demandSelected.status}</td>
                    </tr>
                    {dropdownIsOpen &&
                      instanceHistory?.map((item) => (
                        <tr key={item.instanceHistoryId} className='DocumentationSubProcess'>
                          <td className='DocumentationSubProcessText'>{item.instanceHistoryName}</td>
                          <td className='DocumentationProcessText'>{dashedFormatDateDDMMYYYY(item.createDt)}</td>
                          <td className='DocumentationProcessText'>{''}</td>
                          <td className='DocumentationProcessText'>
                            <button className='DocumentationProcessDownloadButton' onClick={() => downloadFilesWithJSZip(item.instanceHistoryPath)}>
                              <img src={downloadIcon} alt="downloadIcon" />
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                </tbody>
            </table>
        </div>
        <div className='DocumentationProcessBSide'>
            <div className='DocumentationProcessBSideLSide'>
              <div className="DocumentationProcessBSideLSideBox">
                <p className='DocumentationProcessBSideText'>
                  {'Genera una nueva actualización para la instancia actual desde aquí'}
                </p>
                <button
                  className='DocumentationProcessBSideBtn'
                  onClick={() => setActiveViews((activeViews) => ({ ...activeViews, updateProcess: true, documentationProcess: false }))}
                  >
                    {'Nueva Actualización'}
                </button>
              </div>
            </div>
            <div className='DocumentationProcessBSideCSide'>
              <div className="DocumentationProcessBSideCSideBox">
                <button className='DocumentationProcessBSideBtn' onClick={handleResendEmail}>{'Reenviar correo'}</button>
              </div>
            </div>
            <div className='DocumentationProcessBSideRSide'>
                <div className='DocumentationProcessBSideRSideBox'>
                  <p className='DocumentationProcessBSideText'>
                    {'Selecciona estado actual'}
                    <br />
                    {'de la instancia'}
                  </p>
                  <div className='DocumentationProcessBSideRSideBtnBox'>
                    <button
                      className='DocumentationProcessBSideBtn'
                      onClick={() => handleChangeProcessStatus({ statusCode: 2 })}
                      disabled={demandSelected.status === 'Finalizado' || isRequesting}
                    >
                      {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.accept : ''}
                    </button>
                    <button
                      className='DocumentationProcessBSideBtn'
                      onClick={() => handleChangeProcessStatus({ statusCode: 3 })}
                      disabled={demandSelected.status === 'Rechazado' || isRequesting}
                    >
                      {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.reject : ''}
                    </button>
                    <button
                      className='DocumentationProcessBSideBtn'
                      onClick={() => handleChangeProcessStatus({ statusCode: 1 })}
                      disabled={demandSelected.status === 'Pendiente' || isRequesting}
                    >
                      {'Pendiente'}
                    </button>
                  </div>
                </div>
            </div>
        </div>
        <AreYourSureModal
          showModal={showRUSureModal}
          handleClose={handleCloseRUSure}
          functionToRun={() => handlefinishProcess(dataContext, newStatusCode, handleShow, demandSelected.demandId, setRadicateNum, setIsRequesting, handleCloseRUSure)}
          isRequesting={isRequesting}
        />
        <Modal isOpen={showModal}>
          <Modal.Content>
            <p>{`
              ${scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.processFinished.split('*')[0] : ''}
              ${radicateNum}
              ${scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.processFinished.split('*')[2] : ''}
            `}</p>
            <p>{scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.userNoticed : ''}</p>
          </Modal.Content>
          <Modal.Buttons>
            <button
              className='Modal-orangeBtn'
              onClick={() => setActiveViews((activeViews) => ({ ...activeViews, activeProcessListActive: true, documentationProcess: false }))}>
                {scriptsSave && scriptsSave.DocumentationProcessString ? scriptsSave.DocumentationProcessString.accept : ''}
              </button>
          </Modal.Buttons>
        </Modal>
        <Modal isOpen={showModal2}>
          <Modal.Content>
            <p>{'Correo reenviado'}</p>
          </Modal.Content>
          <Modal.Buttons>
            <button
              className='Modal-greenBtn'
              onClick={handleClose2}>
                {scriptsSave && scriptsSave.dataContextStrings ? scriptsSave.dataContextStrings.ok : ''}
              </button>
          </Modal.Buttons>
        </Modal>
    </section>
  )
}
