import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import cardControl from '../../assets/icons/cardControl.png'
import cardMas from '../../assets/icons/cardMas.png'
import cardPremium from '../../assets/icons/cardPremium.png'
import iconVolver from '../../assets/icons/icon_volver.svg'
import iconEnProceso from '../../assets/icons/icon_enproceso.svg'
import iconAutorizado from '../../assets/icons/icon_autorizado.svg'
import iconRechazado from '../../assets/icons/icon_bloqueado.svg'
import iconFinalizado from '../../assets/icons/icon_bien.svg'
import iconRevision from '../../assets/icons/icon_continuar.svg'
import { DataContext } from '../../context/DataContext'
import { formatDateDDMMYYMonthInLetters } from '../../utils/functions'
import { useModal } from '../../hooks/useModal'
import { onDeleteDataClick, onModalButtonClick, onReactivateAccountClick } from './OperativeUsersAdminDetailsFunctions'
import './OperativeUsersAdminDetails.css'
import { Modal } from '../../components/Modal/Modal'
import { ScriptContext } from '../../context/ScriptsContext'
// import { resendEmailEntityAuthorized } from '../../utils/userQueries'

export const OperativeUsersAdminDetails = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { userManagementToBeReviewed } = dataContext.dataState.operative.users
  const { idToken } = dataContext.dataState.operative
  const { planName, credentialId, personId } = userManagementToBeReviewed.specificInfo
  const [showModal, handleShow, handleClose] = useModal()
  const [showModal2, /* handleShow2 */, handleClose2] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const navigate = useNavigate()

  // === BOTÓN DE REENVIAR CORREOS EN AUTORIZACIONES ===
  // const handleResendEmailEntityAuthorized = async () => {
  //   const requestData = {
  //     whiteList: userManagementToBeReviewed.authorizedCompanies[0].whiteListId
  //   }
  //   const response = await resendEmailEntityAuthorized({ idToken, dataContext, requestData })
  //   if (response.response) {
  //     handleShow2()
  //   }
  // }

  return (
    <section className='usersAdminDetails'>
      <h2 className='usersAdminDetailsTitle'>
        {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title1 : ''}
      </h2>

      <div className='usersAdminDetailsMainContent'>
        <div className='usersAdminDetailsContainer1'>
          <div className='usersAdminDetailsPrincipalImgContainer'>
            <img
              src={planName === 'Premium' ? cardPremium : planName === 'Control' ? cardControl : cardMas}
              alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt1 : ''}
              className='usersAdminDetailsPrincipalImg'
            />
          </div>
          <div className='usersAdminDetailsSubcontainer'>
            <h2 className='usersAdminDetailsTitle'>
              {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title2 : ''}
            </h2>

            <div className='usersAdminDetailsInfoContainer'>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle1 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.name}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle2 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.idType}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle3 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.phone}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle4 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.idNum}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle5 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.email}
                </p>
              </div>
              <div></div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {'Ciudad de residencia'}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.city}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {'Género'}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.specificInfo.gender}
                </p>
              </div>
            </div>
          </div>
          <div className='usersAdminDetailsSubcontainer'>
            <h2 className='usersAdminDetailsTitle'>
              {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title3 : ''}
            </h2>
            <div className='usersAdminDetailsInfoContainerColumn'>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle6 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {formatDateDDMMYYMonthInLetters(userManagementToBeReviewed.generalInfo.creationDate)}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle7 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {userManagementToBeReviewed.generalInfo.actualBalance !== 'N/A'
                    ? `$${userManagementToBeReviewed.generalInfo.actualBalance}`
                    : userManagementToBeReviewed.generalInfo.actualBalance
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='usersAdminDetailsContainer2'>
          <div className='usersAdminDetailsSubcontainer'>
            <h2 className='usersAdminDetailsTitle'>
              {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title4 : ''}
            </h2>
            <div className='usersAdminDetailsHistorialRows'>
              {userManagementToBeReviewed.blockedCompanies.length > 0 && (
                <>
                  <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorial'>
                    {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle8 : ''}
                  </h2>
                  <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorialState'>
                    {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle9 : ''}
                  </h2>
                </>
              )}
              {
                userManagementToBeReviewed.blockedCompanies.length > 0 && userManagementToBeReviewed.blockedCompanies.map(company => (
                  <>
                    <p className='usersAdminDetailsCompanyName'>{company.companyComertialName}</p>
                    <div className='usersAdminDetailsCompanyStatus'>
                      <img
                        className='usersAdminDetailsCompanyStatusImg'
                        src={
                          company.statusName === 'Pendiente'
                            ? iconEnProceso
                            : company.statusName === 'Rechazado'
                              ? iconRechazado
                              : company.statusName === 'Finalizado'
                                ? iconFinalizado
                                : iconRevision
                        }
                        alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt2 : ''}
                      />
                    </div>
                  </>
                ))
              }
            </div>
          </div>
          <div className='usersAdminDetailsSubcontainer'>
            <h2 className={`${planName === 'Control' ? 'usersAdminDetailsTitleControl' : 'usersAdminDetailsTitle'}`}>
              {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title5 : ''}
            </h2>
            {planName !== 'Control'
              ? (
                <div className='usersAdminDetailsHistorialRows'>
                  {userManagementToBeReviewed.authorizedCompanies.length > 0 && (
                    <>
                      <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorial'>
                        {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle8 : ''}
                      </h2>
                      <h2 className='usersAdminDetailsSubtitle usersAdminDetailsSubtitleHistorialState'>
                        {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle9 : ''}
                      </h2>
                    </>
                  )}
                  {
                    userManagementToBeReviewed.authorizedCompanies.length > 0 && userManagementToBeReviewed.authorizedCompanies.map(company => (
                      <>
                        <p className='usersAdminDetailsCompanyName'>{company.companyComertialName}</p>
                        <div className='usersAdminDetailsCompanyStatus'>
                          <img
                            className='usersAdminDetailsCompanyStatusImg'
                            src={iconAutorizado}
                            alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt3 : ''}
                          />
                        </div>
                      </>
                    ))
                  }
                </div>
                )
              : <p className='usersAdminDetailsSubtitle usersAdminDetailsNA'>
                {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.warningTxt1 : ''}
              </p>
            }

          </div>
        </div>
      </div>

      <div className='usersAdminDetailsContainer3'>
        <Link to='../' className='usersAdminDetailsBackContainer'>
          <img
            src={iconVolver}
            alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt4 : ''}
          />
          <p>{scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.backBtnTxt : ''}</p>
        </Link>
        <div>
          {/* {userManagementToBeReviewed?.authorizedCompanies.length > 0 &&
            <button
              onClick={handleResendEmailEntityAuthorized}
              className='usersAdminDetailsButton'
            >
              {'Reenviar correo (E. Autorizada)'}
            </button>
          } */}
          {
            userManagementToBeReviewed.specificInfo.personActive
              ? (
                <button
                  onClick={() => onDeleteDataClick(
                    scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings,
                    dataContext,
                    credentialId,
                    idToken,
                    userManagementToBeReviewed.specificInfo.email,
                    setModalMessage,
                    handleShow
                  )}
                  className='usersAdminDetailsButton'
                >
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.btn2Txt : ''}
                </button>
                )
              : (
                <>
                  <button
                    onClick={() => onReactivateAccountClick(
                      scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings,
                      dataContext,
                      idToken,
                      personId,
                      handleShow,
                      setModalMessage
                    )}
                    className='usersAdminDetailsButton'
                  >
                    {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.btn1Txt : ''}
                  </button>
                  <button
                    onClick={() => onDeleteDataClick(
                      scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings,
                      dataContext,
                      credentialId,
                      idToken,
                      userManagementToBeReviewed.specificInfo.email,

                      setModalMessage,
                      handleShow
                    )}
                    className='usersAdminDetailsButton'
                  >
                    {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.btn2Txt : ''}
                  </button>
                </>
                )
          }
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={() => onModalButtonClick(handleClose, navigate)}>
            {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.modalBtnTxt1 : ''}
          </button>
        </Modal.Buttons>
      </Modal>
      <Modal isOpen={showModal2}>
        <Modal.Content>
          <p>{'Correo reenviado'}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button
            className='Modal-greenBtn'
            onClick={handleClose2}>
              {scriptsSave && scriptsSave.dataContextStrings ? scriptsSave.dataContextStrings.ok : ''}
            </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
