import { sendNewInstance } from '../../../../../../utils/userQueries'

export const handleSubmitNewInstance = async ({ instanceData, dataIds, filesUploaded, dataContext, idToken, handleShow }) => {
  const { employeeId, demandId } = dataIds
  const bodyRequest = {
    createInstanceHistoryDto: {
      ...instanceData,
      employeeId,
      demandId
    },
    files: {
      instanceHistory: {
        value: 'demand',
        fileNames: filesUploaded.map((file, index) => ({
          fileName: `instanceUpdate${index + 1}.${file.name.split('.').pop()}`,
          contentType: file.type
        }))
      }
    }
  }

  const filesUploadedFormatted = filesUploaded.map((file, index) => {
    const extension = file.name.split('.').pop() // Obtiene la extensión
    const newFileName = `instanceUpdate${index + 1}.${extension}` // Nombre específico

    return new File([file], newFileName, { type: file.type })
  })

  const responseNewInstance = await sendNewInstance(dataContext, idToken, bodyRequest, filesUploadedFormatted)
  if (responseNewInstance.response) {
    handleShow()
  }
}

export const handleUploadFiles = (event, filesUploaded, setFilesUploaded) => {
  if (event.target.files.length + filesUploaded.length <= 5) {
    handleFormatFiles(event, setFilesUploaded)
  }
}

export const handleFormatFiles = (e, setFilesUploaded) => {
  const fileList = e.target.files
  const formattedFileList = Array.from(fileList)
  setFilesUploaded((prevList) => [...prevList, ...formattedFileList])
}
