import { useEffect, useState } from 'react'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { getCaseTrackingEntityDetail, getEntityWithoutContactChannelDetail, updateCompanyState } from '../../../../utils/userQueries'

export const fetchCaseTrackingEntityDetail = (dataContext, requestData, view, activeViews) => {
  const { dataState, dataDispatch } = dataContext
  const { idToken } = dataState.lawyer
  const [bodyData, setbodyData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      if (view === 'caseTrackingDetail') {
        if (activeViews.activeProcessListActive) {
          const queryResponse = await getCaseTrackingEntityDetail(dataContext, idToken, requestData)
          if (queryResponse.response) {
            const data = queryResponse.body
            const bodyEntityData = {
              name: data.company?.companyBussinesName,
              legalName: data.buyer?.buyerLegalRepresentative,
              contactName: data.buyer?.buyerContactName,
              email: data.company?.companyEmail,
              nit: data.company?.companyNit,
              phone: data.company?.companyPhone,
              logo: data.company?.companyLogoPath,
              demands: data.demands,
              category: data.company?.categoryCompany.categoryCompanyId,
              globalStatus: data.globalStatusProcess
            }
            setbodyData(bodyEntityData)
          }
        }
      } else {
        const queryResponse = await getEntityWithoutContactChannelDetail(dataContext, idToken, requestData)
        if (queryResponse.response) {
          const data = queryResponse.body
          const bodyEntityData = {
            name: data.companyId?.companyBussinesName,
            nit: data.companyId?.companyNit,
            logo: data.companyId?.companyLogoPath,
            userId: data.userId?.userId,
            demandId: data.demandId,
            // userdata
            userName: `${data.userId?.personId?.personName} ${data.userId?.personId?.personLastName}`,
            userEmail: data.userId?.credentialId?.credentialEmail,
            userIdentNum: data.userId?.personId?.personIdentNum,
            userPhone: data.userId?.personId?.personPhone,
            urls: data?.urls
          }
          setbodyData(bodyEntityData)
        }
      }
    }
    // if (caseTrackingEntityDetail.name !== requestData.name && requestData.name) fetchData()
    fetchData()
  }, [activeViews.activeProcessListActive])

  useEffect(() => {
    if (view === 'caseTrackingDetail') {
      const action = {
        type: dataTypes.getCaseTrackingEntityDetail,
        payload: {
          caseTrackingEntityDetail: bodyData
        }
      }
      dataDispatch(action)
    } else {
      const action = {
        type: dataTypes.getEntityWithoutChannelContactDetail,
        payload: {
          entityWithoutChannelContactDetail: bodyData
        }
      }
      dataDispatch(action)
    }
  }, [bodyData])
}

export const handleChangeState = async (
  statusId,
  employeeId,
  caseTrackingEntityDetail,
  dataContext,
  idToken,
  setCurrentStatus,
  setActiveDropdown
) => {
  const { dataDispatch } = dataContext
  const demandList = caseTrackingEntityDetail.demands
  const firstDemand = demandList.find(demand => demand.requirementId.requirementId === 1)
  const bodyRequest = {
    demandId: firstDemand.demandId,
    statusId,
    employeeId
  }

  const response = await updateCompanyState(dataContext, idToken, bodyRequest)
  if (response.response) {
    const action = {
      type: dataTypes.getCaseTrackingEntityDetail,
      payload: {
        caseTrackingEntityDetail: {
          ...caseTrackingEntityDetail,
          globalStatus: statusId
        }
      }
    }

    dataDispatch(action)

    setCurrentStatus(statusId)
    setActiveDropdown(false)
  }
}
