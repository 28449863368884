import { useContext, useEffect, useState } from 'react'
import { DataContext } from '../context/DataContext'

export const usePaginateQuery = (
  contextObjectState,
  payloadProp,
  query,
  dataType,
  idToken,
  dataContext,
  queryBody = null
) => {
  const { dataDispatch } = useContext(DataContext)
  const numberOfItemsToFetch = 6

  const [paginationValues, setPaginationValues] = useState({
    page: contextObjectState.currentPage,
    limit: numberOfItemsToFetch,
    scrollMeasurement: contextObjectState.scrollMeasurement,
    totalItems: null,
    totalPages: null
  })

  const [isNextActive, setIsNextActive] = useState(true)
  const [isPrevActive, setIsPrevActive] = useState(true)
  const { page, limit, scrollMeasurement } = paginationValues

  useEffect(() => {
    const fetch = async () => {
      const fetchedData = await getFetchData()
      if (fetchedData?.body?.meta?.itemCount === 0 && page > 1) {
        setPaginationValues({
          ...paginationValues,
          page: page - 1
        })
      }
      if (!isNextActive) {
        setIsNextActive(true)
      }
      if (!isPrevActive) {
        setIsPrevActive(true)
      }
    }
    fetch()
    const action = {
      type: dataType,
      payload: {
        [payloadProp]: {
          ...contextObjectState,
          currentPage: page,
          scrollMeasurement
        }
      }
    }
    dataDispatch(action)
  }, [paginationValues.page, queryBody])

  const getFetchData = async () => {
    const queryResponse = queryBody === null
      ? await query(idToken, dataContext, page, limit)
      : await query(idToken, dataContext, queryBody, page, limit)
    if (queryResponse.response) {
      setPaginationValues({
        ...paginationValues,
        page: queryResponse.body.meta.currentPage,
        totalItems: queryResponse.body.meta.totalItems,
        totalPages: queryResponse.body.meta.totalPages
      })
      const action = {
        type: dataType,
        payload: {
          [payloadProp]: {
            currentPage: page,
            list: queryResponse.body.items,
            scrollMeasurement: paginationValues.scrollMeasurement
          }
        }
      }
      dataDispatch(action)
    }
    return queryResponse
  }

  return [
    paginationValues,
    setPaginationValues,
    isNextActive,
    setIsNextActive,
    isPrevActive,
    setIsPrevActive
  ]
}
