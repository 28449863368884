import React, { useState, useEffect, useContext } from 'react'
import { Link, NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import noContactEntitiesIcon from '../../../assets/icons/icon_nocontactentity.svg'
import iconBack from '../../../assets/icons/icon_volver.svg'
import './RequestsLayout.css'
import { ScriptContext } from '../../../context/ScriptsContext'
import { DataContext } from '../../../context/DataContext'
import { getEntityWithoutContactChannelList } from '../../../utils/userQueries'

export const RequestsLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [isDisabled, setIsDisabled] = useState(false)
  const dataContext = useContext(DataContext)
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer
  // const [, setJsonData] = useState(null)
  // const [, setFileName] = useState(null)

  /* const handleFileUpload = async (e) => {
    setFileName(e.target.files[0].name.replace('.csv', ''))
    const data = await csvToJson(
      e.target.files[0],
      e.target.files[0].name.replace('.csv', '')
    )
    setJsonData(data)
  } */

  // const cargaMasiva = async () => {
  //   const dataInserted = (await setCorrectRegisters(jsonData, fileName))
  //     .finalResult
  //   console.log(dataInserted)
  //   if (dataInserted.length === jsonData.length) {
  //     alert(`Se cargaron ${dataInserted.length} registros exitosamente`)
  //   } else {
  //     alert(
  //       `Se cargaron ${dataInserted.length} registros exitosamente y se presentó error en la carga de ${jsonData.length} registros, puede corregir los datos y volver a cargarlos para registro`
  //     )
  //   }
  // }

  const handleCaseTrackingBackBtn = (pathname) => {
    if (pathname.endsWith('SeguimientoCasosDetalle')) {
      navigate('./SeguimientoCasos')
    } else if (pathname.endsWith('SeguimientoCasosEntidadDetalle')) {
      navigate('./SeguimientoCasos/SeguimientoCasosDetalle')
    } else navigate('.')
  }

  const handleDisableNavigation = (pathname) => {
    if (pathname.endsWith('Solicitudes')) {
      setIsDisabled(true)
    } else if (pathname.endsWith('GestionUsuarios') || pathname.endsWith('SeguimientoCasos')) setIsDisabled(true)
    else setIsDisabled(false)
  }

  const [paginationValuesItems, setPaginationValuesItems] = useState(0)

  const getItems = async () => {
    try {
      const items = await getEntityWithoutContactChannelList(idToken, dataContext)
      setPaginationValuesItems(items.body.meta.totalItems)
    } catch (error) {
      console.error('Error obteniendo los items:', error)
    }
  }

  useEffect(() => {
    handleDisableNavigation(pathname)
    getItems()
  }, [pathname])

  useEffect(() => {
    getItems()
  }, [paginationValuesItems])

  const isInEntityReponseViews = pathname.split('/')[4]?.startsWith('Respuesta')
  return (
    <section id="requestsLayoutSize">
      <div className="requestsLayout">
        <div className="requestsLayoutNavLinksContainer">
          {pathname.includes('EntidadesSinCanalDeContacto')
            ? <NavLink
                to="EntidadesSinCanalDeContacto"
                className={'entriesActiveHeader'}
                end>
                <p className="requestsLayoutNavLinkParagraph">
                  {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.headerParagraph3 : ''}
                </p>
              </NavLink>
            : <>
                <NavLink
                  to=""
                  className={({ isActive }) =>
                    isActive || isInEntityReponseViews ? 'entriesActiveHeader' : ''
                  }
                end>
                  <p className="requestsLayoutNavLinkParagraph">
                    {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.headerParagraph1 : ''}
                  </p>
                </NavLink>
                <NavLink
                  to="SeguimientoCasos"
                  className={({ isActive }) =>
                    isActive ? 'entriesActiveHeader' : ''
                  }>
                  <p className="requestsLayoutNavLinkParagraph">
                    {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.headerParagraph2 : ''}
                  </p>
                </NavLink>
              </>
          }
        </div>
        {
          !pathname.endsWith('EntidadesSinCanalDeContacto') &&
            <Link
              className="entriesHeaderContainerButton"
              to="./EntidadesSinCanalDeContacto"
            >
              <img
                alt={scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.altImageTxt1 : ''}
                className="entriesHeaderContainerImg"
                src={noContactEntitiesIcon}
              />
              {
                paginationValuesItems > 0 &&
                paginationValuesItems < 100
                  ? (
                  <span className="entriesHeaderContainerUnreadPqrs">
                    {paginationValuesItems}
                  </span>
                    )
                  : paginationValuesItems >= 100
                    ? (
                  <span className="entriesHeaderContainerUnreadPqrs">
                    {scriptsSave && scriptsSave.entradasLayoutAdminStrings ? scriptsSave.entradasLayoutAdminStrings.maxUnreadPQRSTxt1 : ''}
                  </span>
                      )
                    : null
              }
            </Link>
        }
      </div>
      <hr className="requestsLayoutSeparator" />
      <Outlet />
      {/* Bulk upload */}
      {/* <div className="TestCargaMasivaContainer">
        <input type="file" onChange={handleFileUpload} />
        <button className="TestCargaMasivaBtn" onClick={cargaMasiva}>
          Test Carga Masiva
        </button>
      </div> */}
      {!isDisabled &&
        <button className="requestLayoutBackBox" onClick={() => handleCaseTrackingBackBtn(pathname)}>
          <img
            src={iconBack}
            alt={scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.altImageTxt2 : ''}
          />
          <span>{scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.backBtnTxt : ''}</span>
        </button>
      }
    </section>
  )
}
