import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../../../../../../components/Modal/Modal'
import { useModal } from '../../../../../../hooks/useModal'
import { arrayCompact, handleSendNewProcess, handleUploadFiles, removeFiles } from './SelectProcessFunctions'
import iconUpload from '../../../../../../assets/icons/icon_subir_archivos.svg'
import iconRemove from '../../../../../../assets/icons/icon_remove.svg'
import './SelectProcess.css'
import { DataContext } from '../../../../../../context/DataContext'
import { ScriptContext } from '../../../../../../context/ScriptsContext'

export const SelectProcess = ({ isActive, requestData, demands, companyInfo }) => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, infoSaved } = dataContext
  const { idToken, requirementList, employeeId } = dataState.lawyer

  const [showModal, handleShow] = useModal()
  const navigate = useNavigate()
  const [filesUploaded, setFilesUploaded] = useState([])
  const [requirementId, setRequirementId] = useState(0)
  const [requirementListFormatted, setRequirementListFormatted] = useState([])

  useEffect(() => {
    const listFormatted = requirementList.filter((requirement) => !demands?.some(item => requirement.requirementId === item.requirementId.requirementId))
    const listFormattedWithoutRequirement2 = listFormatted.filter((requirement) => requirement.requirementId !== 2)
    if (companyInfo.category !== 2) setRequirementListFormatted(listFormattedWithoutRequirement2)
    else setRequirementListFormatted(listFormatted)
  }, [isActive])

  if (!isActive) return null
  return (
    <section id='SelectProcess'>
      <div className='SelectProcessBox'>
        <div className='SelectProcessLeftSide'>
          <h2 className='SelectProcessBoxTitle'>{scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.selectProcess : ''}</h2>
          {
            requirementListFormatted.map((requirement) => (
              <div key={requirement.requirementId} className='SelectProcessInputBox'>
                <input
                  type="radio"
                  name="group1"
                  value={requirement.requirementId}
                  className='SelectProccessCheck'
                  onClick={(e) => setRequirementId(e.target.value)}
                />
                <span className='SelectProcessBoxText'>
                  {requirement.requirementName}
                </span>
              </div>
            ))
          }
        </div>
        <div className='SelectProcessRightSide'>
          {filesUploaded.length
            ? <>
                <h2 className='SelectProcessBoxTitle'>{scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.annexesUploaded : ''}</h2>
                <div className='SelectProcessBoxFiles'>
                  {filesUploaded.map((file, index) => (
                    <div className='SelectProcessBoxFile' key={index}>
                      <p className='SelectProcessBoxFileName'>{arrayCompact(file.name)}</p>
                      <button
                        className='SelectProcessBoxFileRemoveBtn'
                        onClick={() => removeFiles(file.name, filesUploaded, setFilesUploaded)}
                      >
                        <img
                          src={iconRemove}
                          alt={scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.altImageTxt1 : ''}
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </>
            : <h2 className='SelectProcessBoxTitle NoAnnexes'>{scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.noAnnexesUploaded : ''}</h2>
          }
        </div>
      </div>
      <div className='SelectProcessBtnBox'>
        <button className='SelectProcessUploadSendButton'>
          {'Descargar informe'}
        </button>
        <div className='SelectProcessUploadSendBox'>
          <input type="file" id='annexesFilesInput' onChange={(e) => handleUploadFiles(e, setFilesUploaded)} accept='.jpg, .jpeg, .png, .pdf'/>
          <label htmlFor='annexesFilesInput' className='SelectProcessUploadButton'>
            {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.uploadAnnexes : ''}
            <img
              src={iconUpload}
              className='SelectProcessUploadButtonImg'
              alt={scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.altImageTxt2 : ''}
            />
          </label>
          <button
            className='SelectProcessSendButton'
            onClick={() =>
              handleSendNewProcess(
                dataContext,
                idToken,
                filesUploaded,
                requirementId,
                requestData,
                handleShow,
                employeeId
              )}
            disabled={!filesUploaded.length || !requirementId}
          >
            {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.send : ''}
          </button>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.processSuccess : ''}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={() => navigate('../SeguimientoCasos/SeguimientoCasosDetalle', { state: infoSaved })}>
            {scriptsSave && scriptsSave.SelectProcessString ? scriptsSave.SelectProcessString.accept : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
