export const dataTypes = {
  logout: '[SESSION] logout',
  administrativeLogin: '[ADMINISTRATIVE] login',
  setEmployeesList: '[ADMINISTRATIVE] setEmployeesList',
  roleIdToBeCreated: '[ADMINISTRATIVE] roleIdToBeCreated',
  addEmployeeToEmployeesList: '[ADMINISTRATIVE] addEmployeeToEmployeesList',
  updateEmployeeInEmployeesList: '[ADMINISTRATIVE] updateEmployeeInEmployeesList',
  deleteEmployeeInEmployeesList: '[ADMINISTRATIVE] deleteEmployeeInEmployeesList',
  employeeToBeEdited: '[ADMINISTRATIVE] employeeToBeEdited',
  updateDataBases: '[ADMINISTRATIVE] updateDataBases',
  setDatabaseToBeReviewed: '[ADMINISTRATIVE] setDatabaseToBeReviewed',
  citiesDocTypesGenders: '[ADMINISTRATIVE] citiesDocTypesGenders',
  operativeLogin: '[OPERATIVE] login',
  updateNewUsersEntries: '[OPERATIVE] updateNewUsersEntries',
  updateNewEntitiesEntries: '[OPERATIVE] updateNewEntitiesEntries',
  updateUserPocket: '[OPERATIVE] updateUserPocket',
  updatePaymentOfEntities: '[OPERATIVE] updatePaymentOfEntities',
  setUserPocketToBeReviewed: '[OPERATIVE] setUserPocketToBeReviewed',
  setPaymentOfEntityToBeReviewed: '[OPERATIVE] setPaymentOfEntityToBeReviewed',
  updateTuDataControlPayments: '[OPERATIVE] updateTuDataControlPayments',
  setNewUserEntryToBeReviewd: '[OPERATIVE] setNewUserEntryToBeReviewd',
  setNewEntityEntryToBeReviewd: '[OPERATIVE] setNewEntityEntryToBeReviewd',
  setTuDataControlPaymentToBeReviewd: '[OPERATIVE] setTuDataControlPaymentToBeReviewd',
  updateDeleteRequests: '[OPERATIVE] updateDeleteRequests',
  updateUsersManagement: '[OPERATIVE] updateUsersManagement',
  updateChangePlanManagement: '[OPERATIVE] updateChangePlanManagement',
  updateCompaniesManagement: '[OPERATIVE] updateCompaniesManagement',
  setUserManagementToBeReviewed: '[OPERATIVE] setUserManagementToBeReviewed',
  setAdminChangePlanToBeReviewed: '[OPERATIVE] setAdminChangePlanToBeReviewed',
  setUpdateDeleteRequests: '[OPERATIVE] setUpdateDeleteRequests',
  setCompanyManagementToBeReviewd: '[OPERATIVE] setCompanyManagementToBeReviewd',
  updatePqrsOfUnregisteredUsers: '[OPERATIVE] updatePqrsOfUnregisteredUsers',
  setPqrsOfUnregisteredUsers: '[OPERATIVE] setPqrsOfUnregisteredUsers',
  setPaginationOfUnreadPqrs: '[OPERATIVE] setPaginationOfUnreadPqrs',
  updatePaymentHistoryPerContactNotAllowed: '[OPERATIVE] updatePaymentHistoryPerContactNotAllowed',
  setPaymentHistoryDetailsPerContactNotAllowed: '[OPERATIVE] setPaymentHistoryDetailsPerContactNotAllowed',
  setCsvDataOnMassiveUploads: '[OPERATIVE] setCsvDataOnMassiveUploads',
  setCompanyOnMassiveUploads: '[OPERATIVE] setCompanyOnMassiveUploads',
  setUserEntryToBeModified: '[OPERATIVE] setUserEntryToBeModified',
  setEntityEntryToBeModified: '[OPERATIVE] setEntityEntryToBeModified',
  updatePqrsList: '[OPERATIVE] updatePqrsList',
  getPqrsDetail: '[OPERATIVE] getPqrsList',
  getStates: '[OPERATIVE] getStates',
  getCities: '[OPERATIVE] getCities',
  getDocTypes: '[OPERATIVE] getDocTypes',
  getGenders: '[OPERATIVE] getGenders',
  lawyerLogin: '[LAWYER] login',
  getRequirementList: '[LAWYER] getRequirementList',
  getInstanceUpdatesList: '[LAWYER] getInstanceUpdatesList',
  getNotAllowedContactList: '[LAWYER] getNotAllowedContactList',
  getNotAllowedContactDetail: '[LAWYER] getNotAllowedContactDetail',
  getResponsesByEntity: '[LAWYER] getResponsesByEntity',
  getCaseTrackingList: '[LAWYER] getCaseTrackingList',
  getCaseTrackingUserDetail: '[LAWYER] getCaseTrackingUserDetail',
  getCaseTrackingEntityDetail: '[LAWYER] getCaseTrackingEntityDetail',
  getEntityWithoutChannelContactList: '[LAWYER] getEntityWithoutChannelContactList',
  getEntityWithoutChannelContactDetail: '[LAWYER] getEntityWithoutChannelContactDetail',
  setResponseByEntityDetail: '[LAWYER] setResponseByEntityDetail',
  setUserAndEntityToBeReviewed: '[LAWYER] setUserAndEntityToBeReviewed'
}
