import React from 'react'
import './RejectDelete.css'
import { CharCounter } from '../../../../../../components/CharCounter/CharCounter'
import { validateTextarea } from '../../UserDetailFunctions'

export const RejectDelete = ({ rejectResponse, setRejectResponse }) => {
  return (
    <div id='RejectDelete'>
        <textarea
            className='RejectDeleteContentTextarea'
            onChange={(e) => validateTextarea(e, setRejectResponse)}
            value={rejectResponse}
        >
        </textarea>
        <CharCounter description={rejectResponse}/>
    </div>
  )
}
