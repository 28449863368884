import { validateNoWhiteSpacesAtBeginning } from '../../utils/validateFunctions'

export const onSearchInputChange = (
  e,
  setInputValue,
  setNewValue
) => {
  const typedValue = e.target.value

  if (validateNoWhiteSpacesAtBeginning(typedValue)) {
    setInputValue(typedValue)
    setNewValue(typedValue)
  }
}

export const onSearchInputClick = (inputValue, newQueryBody, setQueryBody) => {
  inputValue ? setQueryBody(newQueryBody) : setQueryBody(null)
}

export const onSelectedOptionClick = (
  option,
  setSelectedOption,
  list,
  updateList,
  filterBy,
  show,
  setShow
) => {
  setShow(!show)
  setSelectedOption(option)
  const listFormatted = list.filter(element => (
    element[filterBy]
      .toLowerCase()
      .includes(option.toLowerCase())
  ))
  updateList(listFormatted)
}

export const onAscendantSelectedOptionClick = (
  option,
  setSelectedOption,
  list,
  updateList,
  filterBy,
  sortType,
  show,
  setShow,
  option1,
  option2,
  option3,
  option4
) => {
  setShow(!show)
  setSelectedOption(option)

  const tempList = [...list]

  if (option === option4) {
    if (sortType === 'number') {
      tempList.sort((a, b) => b[filterBy] - a[filterBy])
    } else if (sortType === 'string') {
      tempList.sort((a, b) => b[filterBy].localeCompare(a[filterBy]))
    }
  } else if (option === option3) {
    if (sortType === 'number') {
      tempList.sort((a, b) => a[filterBy] - b[filterBy])
    } else if (sortType === 'string') {
      tempList.sort((a, b) => a[filterBy].localeCompare(b[filterBy]))
    }
  } else if (option === option2) {
    tempList.sort((a, b) => {
      const date1 = dateTransform(a[filterBy])
      const date2 = dateTransform(b[filterBy])
      return date1 - date2
    })
  } else if (option === option1) {
    tempList.sort((a, b) => {
      const date1 = dateTransform(a[filterBy])
      const date2 = dateTransform(b[filterBy])
      return date2 - date1
    })
  }
  updateList(tempList)
}

const dateTransform = (stringDate, filterBy) => {
  const dateSplitted = stringDate.split(' ')
  const month = dateSplitted[1]
  const day = parseInt(dateSplitted[0])
  const year = parseInt(dateSplitted[2])

  const months = {
    Ene: 0,
    Feb: 1,
    Mar: 2,
    Abr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Ago: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dic: 11
  }

  const dateTransformed = new Date(year, months[month], day)
  return dateTransformed
}
