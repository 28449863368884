import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import dbImage from '../../../assets/icons/icon_bd-operativo.svg'
import { useUploadMassive } from '../hooks/useUploadMassive'
import { ScriptContext } from '../../../context/ScriptsContext'
import { Modal } from '../../../components/Modal/Modal'
import { useModal } from '../../../hooks/useModal'

import './UserUploads.css'

export const UserUploads = () => {
  const navigate = useNavigate()
  const [showModal, handleShow] = useModal()
  const { scriptsSave } = useContext(ScriptContext)
  const [progress, setProgress] = useState(0)
  useUploadMassive(setProgress)

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        handleShow()
      }, 1000)
    }
  }, [progress])

  return (
    <section id="UserUploads">
      <div className="UserUploadsMainContainer">
        <div className="UserUploadsImageBox">
          <img
            src={dbImage}
            alt={scriptsSave && scriptsSave.userUploadsString ? scriptsSave.userUploadsString.principalSectionAltImgText1 : ''}
          />
        </div>

        <div className="bulkUploadSubContainer2">
          <div className="bulkUploadProgressBarContainer">
            <span className="bulkUploadChargeType">
              {scriptsSave && scriptsSave.userUploadsString ? scriptsSave.userUploadsString.progressBarTitle : ''}
            </span>
            <div className="progressContainer">
              <div
                className={`${
                  progress < 100 ? 'progressBar' : 'progressBarFileCharged'
                }`}
                style={{ width: `${progress}%` }}
              >
                <span
                  className={`${
                    progress < 100
                      ? 'progressBarMessage'
                      : 'progressBarMessageFileCharged'
                  }`}
                >
                  {
                    progress < 100
                      ? `${scriptsSave && scriptsSave.compString ? scriptsSave.compString.fileCharging : ''}`
                      : `${scriptsSave && scriptsSave.compString ? scriptsSave.compString.fileCharged : ''}`
                  }
                </span>
              </div>
            </div>
          </div>
          <div className="upLoadMessageState">
            <span className="upLoadMessageStatePercentage">
              {parseFloat(progress).toFixed(1)}
              {scriptsSave && scriptsSave.compString ? scriptsSave.compString.percentage : ''}
            </span>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{'¡Archivo cargado con éxito!'}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button onClick={() => navigate('../CargasMasivas')} className='Modal-orangeBtn'>
            {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.continue : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
