import React from 'react'
import { CharCounter } from '../../../../components/CharCounter/CharCounter'
import './RejectChangePlanSection.css'
import { validateTextarea } from '../../views/ChangePlanDetail/ChangePlanDetailFunctions'

export const RejectChangePlanSection = ({ details, responseText }) => {
  return (
    <div className='RejectChangePlanSection'>
        <div className='RejectChangePlanSectionTitle'>
            <h2 className='RejectChangePlanSectionTitleResponse'>{'Respuesta'}</h2>
            <p className='RejectChangePlanSectionTitleText'>{'*Escribe la respuesta, le llegará al solicitante por correo.'}</p>
        </div>
        <div className='RejectChangePlanSectionContent'>
            <textarea
              className='RejectChangePlanSectionContentTextarea'
              value={details}
              onChange={(e) => validateTextarea(e, responseText)}></textarea>
            <CharCounter description={details}/>
        </div>
    </div>
  )
}
