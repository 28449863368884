import { getPolitics } from '../../../../utils/queries'
import { patchDeleteUser, updatePQRS, updateUserPlan } from '../../../../utils/userQueries'
import { hasRepeatedCharactersAtMiddle, validateNoWhiteSpacesAtBeginning } from '../../../../utils/validateFunctions'

export const onReactivateAccountClick = async (
  adminChangePlanToBeReviewed,
  idToken,
  dataContext,
  employeeId,
  handleShow,
  setModalMessage
) => {
  const plan = adminChangePlanToBeReviewed.planToChangeInfo.planId
  const userId = adminChangePlanToBeReviewed.specificInfo.userId
  const requestId = adminChangePlanToBeReviewed.specificInfo.requestId
  const politicsList = await getPolitics(plan)
  const politicBody = politicsList.body

  const planTypes = ['PLUS', 'PREMIUM', 'CONTROL']
  const politicList = []
  politicBody.map((item) => {
    if (planTypes.some((planType) => item.politicName.includes(planType))) {
      const id = {
        politicId: item.politicId
      }
      politicList.push(id)
    }
    return item
  })
  await updateUserPlan(plan, politicList, userId, idToken, dataContext)
  const pqrResponse = await setPqrResponse(userId, employeeId, requestId, dataContext, idToken, '2')

  if (pqrResponse.response) {
    setModalMessage('Cambio de plan aprobado')
    handleShow()
  }
}

export const rejectAccountChange = async (
  adminChangePlanToBeReviewed,
  idToken,
  dataContext,
  employeeId,
  handleShow,
  setModalMessage,
  responseFromDetails
) => {
  const userId = adminChangePlanToBeReviewed.specificInfo.userId
  const requestId = adminChangePlanToBeReviewed.specificInfo.requestId
  const pqrResponse = await setPqrResponse(userId, employeeId, requestId, dataContext, idToken, '3', responseFromDetails)

  if (pqrResponse.response) {
    setModalMessage('Cambio de plan rechazado')
    handleShow()
  }
}

export const validateTextarea = (e, setState) => {
  const text = e.target.value

  if (text.length > 1800) return

  if (validateNoWhiteSpacesAtBeginning(text)) {
    if (hasRepeatedCharactersAtMiddle(text)) {
      return ''
    }
    setState(text)
  }
}

export const onModalButtonClick = (handleClose, navigate) => {
  handleClose()
  navigate('../')
}

export const onDeleteDataClick = async (
  operativeUsersAdminDetailsStrings,
  dataContext,
  credentialId,
  idToken,
  email,
  setModalMessage,
  handleShow
) => {
  const queryBody = {
    email
  }
  const patchDeleteUserResponse = await patchDeleteUser(dataContext, credentialId, queryBody, idToken)
  if (patchDeleteUserResponse.response) {
    setModalMessage(operativeUsersAdminDetailsStrings.modalMessage2)
    handleShow()
  }
}

export const setPqrResponse = async (userId, employeeId, requestId, dataContext, idToken, statusId, responseFromDetails = null) => {
  const pqrsResponseBody = {
    userId,
    response: responseFromDetails === null ? statusId === '2' ? 'Cambio de plan aprobado' : 'Cambio de plan rechazado' : responseFromDetails,
    statusId,
    employeeId
  }
  const pqrsId = 7
  return await updatePQRS(dataContext, idToken, requestId, pqrsResponseBody, pqrsId)
}
