import { calculateNumberOfDigits } from '../../../../utils/functions'

export const formatNotAllowedContactList = (
  NotAllowedContactList,
  setNotAllowedContactListFormatted,
  page,
  limit
) => {
  const numberOfDigits = calculateNumberOfDigits(NotAllowedContactList)
  const currentItem = page

  setNotAllowedContactListFormatted(
    NotAllowedContactList.map((obj, index) => {
      let id = currentItem * limit - limit + 1
      if (id < 1) id = 1
      return {
        id: String(id + index).padStart(numberOfDigits, '0'),
        name: `${obj?.userId?.personId?.personName} ${obj?.userId?.personId?.personLastName}`,
        claimTo: obj?.content,
        requestId: obj?.requestId,
        processType: obj?.typeProcess?.charAt(0)?.toUpperCase() + obj?.typeProcess?.slice(1)?.toLowerCase()
      }
    })
  )
}
