import { textWithoutEspecials } from '../../../../../../utils/functions'
import { sendNewProcess } from '../../../../../../utils/userQueries'

export const arrayCompact = (fileName) => {
  const extension = fileName.substring(fileName.lastIndexOf('.') + 1)
  const shortenedName = fileName.substring(0, fileName.lastIndexOf('.'))
  const truncatedName = shortenedName.substring(shortenedName.length - 5)
  return truncatedName + '.' + extension
}

export const handleUploadFiles = (e, setFilesUploaded) => {
  const fileList = e.target.files[0]
  setFilesUploaded([fileList])
}

export const removeFiles = (fileName, filesUploaded, setFilesUploaded, handleShow) => {
  const newFileList = filesUploaded.filter((file) => file.name !== fileName)
  setFilesUploaded(newFileList)
}

export const handleSendNewProcess = async (dataContext, idToken, filesUploaded, requirementId, requestData, handleShow, employeeId) => {
  const { userId, companyId, userIdentNum, companyNit } = requestData

  const filesArrayFormatted = filesUploaded.map((file) => ({
    fileName: textWithoutEspecials(file.name),
    contentType: file.type
  }))

  const requestBody = {
    companyId,
    userId,
    userIdentNum,
    companyNit,
    requirementId,
    files: {
      value: 'demand',
      fileNames: filesArrayFormatted
    },
    employeeId
  }

  const responseReq = await sendNewProcess(dataContext, idToken, requestBody)

  if (responseReq.response) {
    const keyNames = Object.keys(responseReq.body)
    keyNames.forEach(async (fileName, index) => {
      const urlToSendFile = responseReq.body[textWithoutEspecials(fileName)].url
      await fetch(urlToSendFile, {
        method: 'PUT',
        body: filesUploaded[index]
      })
    })
    handleShow()
  }
}
