import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { /* onDeleteDataClick,  */onModalButtonClick, onReactivateAccountClick, rejectAccountChange } from './ChangePlanDetailFunctions.js'
import { ScriptContext } from '../../../../context/ScriptsContext'
import { Modal } from '../../../../components/Modal/Modal.jsx'
import { useModal } from '../../../../hooks/useModal.js'
import { DataContext } from '../../../../context/DataContext.js'
import { formatDateDDMMYYMonthInLetters } from '../../../../utils/functions.jsx'
import cardControl from '../../../../assets/icons/cardControl.png'
import cardMas from '../../../../assets/icons/cardMas.png'
import cardPremium from '../../../../assets/icons/cardPremium.png'
import iconVolver from '../../../../assets/icons/icon_volver.svg'
import './ChangePlanDetail.css'
import { RejectChangePlanSection } from '../../components/RejectChangePlanSection/RejectChangePlanSection.jsx'
import { CompaniesInProcessList } from '../../components/CompaniesInProcessList/CompaniesInProcessList.jsx'

export const ChangePlanDetail = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { adminChangePlanToBeReviewed } = dataContext.dataState.operative.users
  const { idToken, employeeId } = dataContext.dataState.operative
  const { planName/* , credentialId */ } = adminChangePlanToBeReviewed.specificInfo
  const [showModal, handleShow, handleClose] = useModal()
  const [modalMessage, setModalMessage] = useState('')
  const [isRejecting, setIsRejecting] = useState(false)
  const navigate = useNavigate()
  const [responseFromDetails, setResponseFromDetails] = useState('')
  return (
    <section className='usersAdminDetails'>
      <h2 className='usersAdminDetailsTitle'>
        {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title1 : ''}
      </h2>
      <div className='usersAdminDetailsMainContent'>
        <div className='usersAdminDetailsContainer1'>
          <div className='usersAdminDetailsPrincipalImgContainer'>
            <img
              src={planName === 'Premium' ? cardPremium : planName === 'Control' ? cardControl : cardMas}
              alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt1 : ''}
              className='usersAdminDetailsPrincipalImg'
            />
          </div>
          <div className='usersAdminDetailsSubcontainer'>
            <h2 className='usersAdminDetailsTitle'>
              {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title2 : ''}
            </h2>

            <div className='usersAdminDetailsInfoContainer'>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle1 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.name}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle2 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.idType}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle3 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.phone}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle4 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.idNum}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.subtitle5 : ''}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.email}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {'Registro usuario'}
                </h2>
                <p className='usersAdminDetailsInfoData greyColor'>
                  {formatDateDDMMYYMonthInLetters(adminChangePlanToBeReviewed.generalInfo.creationDate)}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {'Ciudad de residencia'}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.city}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {'Género'}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {adminChangePlanToBeReviewed.specificInfo.gender}
                </p>
              </div>
            </div>
          </div>
          <div className='usersAdminDetailsSubcontainer'>
            <h2 className='usersAdminDetailsTitle'>
              {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.title3 : ''}
            </h2>

            <div className='usersAdminDetailsInfoContainerColumn'>
              <div className='usersAdminDetailsInfo'>
                <h2 className='usersAdminDetailsSubtitle'>
                  {'Cambio de plan'}
                </h2>
                <p className='usersAdminDetailsInfoData'>
                  {`Plan Data ${adminChangePlanToBeReviewed.planToChangeInfo.newPlanName === 'Plus' ? 'Más' : adminChangePlanToBeReviewed.planToChangeInfo.newPlanName}`}
                </p>
              </div>
              <div className='usersAdminDetailsInfo'>
                <img src={
                  adminChangePlanToBeReviewed.planToChangeInfo.newPlanName === 'Premium'
                    ? cardPremium
                    : adminChangePlanToBeReviewed.planToChangeInfo.newPlanName === 'Control'
                      ? cardControl
                      : cardMas
                  } className='usersAdminDetailsInfoPlanImg' alt="changePlanIcon" />
              </div>
            </div>
          </div>
        </div>
        {!isRejecting ? <CompaniesInProcessList/> : <RejectChangePlanSection details={responseFromDetails} responseText={setResponseFromDetails} />}
      </div>

      <div className='usersAdminDetailsContainer3'>
        <Link to='../AdmCambioPlan' className='usersAdminDetailsBackContainer'>
          <img
            src={iconVolver}
            alt={scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.altImageTxt4 : ''}
          />
          <p>{scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.backBtnTxt : ''}</p>
        </Link>
        <div>
          {!isRejecting
            ? <>
                <button
                  onClick={() => onReactivateAccountClick(
                    adminChangePlanToBeReviewed,
                    idToken,
                    dataContext,
                    employeeId,
                    handleShow,
                    setModalMessage
                  )}
                  className='usersAdminDetailsButton'
                >
                  {'Aprobar cambio'}
                </button>
                <button
                  onClick={() => setIsRejecting(true)}
                  className='usersAdminDetailsButton'
                >
                  {'Rechazar cambio'}
                </button>
              </>
            : <>
                <button
                  onClick={() => setIsRejecting(false)}
                  className='usersAdminDetailsButton'
                >
                  {'Cancelar'}
                </button>
                <button
                    /* onClick={() => onDeleteDataClick(
                      scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings,
                      dataContext,
                      credentialId,
                      idToken,
                      adminChangePlanToBeReviewed.specificInfo.email,

                      setModalMessage,
                      handleShow
                    )} */
                    onClick={() => rejectAccountChange(
                      adminChangePlanToBeReviewed,
                      idToken,
                      dataContext,
                      employeeId,
                      handleShow,
                      setModalMessage,
                      responseFromDetails
                    )}
                    className='usersAdminDetailsButton'
                    disabled={responseFromDetails.length < 10}
                  >
                  {'Enviar respuesta'}
                </button>
              </>
          }
        </div>
      </div>
      <Modal isOpen={showModal}>
        <Modal.Content>
          <p>{modalMessage}</p>
        </Modal.Content>
        <Modal.Buttons>
          <button className='Modal-orangeBtn' onClick={() => onModalButtonClick(handleClose, navigate)}>
            {scriptsSave && scriptsSave.operativeUsersAdminDetailsStrings ? scriptsSave.operativeUsersAdminDetailsStrings.modalBtnTxt1 : ''}
          </button>
        </Modal.Buttons>
      </Modal>
    </section>
  )
}
