import React, { useContext, useEffect, useState } from 'react'
import './CaseTrackingEntityDetail.css'
import { ActiveProcessList } from './components/ActiveProcessList/ActiveProcessList'
import { DocumentationProcess } from './components/DocumentationProcess/DocumentationProcess'
import { SelectProcess } from './components/SelectProcess/SelectProcess'
import { SelectProcessNoContact } from './components/SelectProcessNoContact/SelectProcessNoContact'
import { useLocation } from 'react-router-dom'
import { DataContext } from '../../../../context/DataContext'
import { fetchCaseTrackingEntityDetail, handleChangeState } from './CaseTrackingEntityDetailFunctions'
import { ScriptContext } from '../../../../context/ScriptsContext'
import orangeArrowIcon from '../../../../assets/icons/icon_flecha_naranja.svg'
import { UpdateProcess } from './components/UpdateProcess/UpdateProcess'

export const CaseTrackingEntityDetail = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, infoSaved } = dataContext
  const { state: requestData } = useLocation()
  const { view, companyId, userId, requestId, userIdentNum } = requestData || infoSaved
  const { caseTracking, idToken, employeeId } = dataState.lawyer
  const { caseTrackingEntityDetail, entityWithoutChannelContactDetail } = caseTracking

  const [activeViews, setActiveViews] = useState({
    activeProcessListActive: view === 'caseTrackingDetail',
    selectProcess: false,
    documentationProcess: false,
    updateProcess: false,
    selectProcessNoContact: view === 'noContact'
  })
  const [emailSplitted, setEmailSplitted] = useState({
    user: '',
    domain: ''
  })

  const [demandSelected, setDemandSelected] = useState({})
  const [activeDropdown, setActiveDropdown] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(1)

  useEffect(() => {
    setCurrentStatus(caseTrackingEntityDetail.globalStatus)
  }, [caseTrackingEntityDetail])

  const requestObject = view === 'caseTrackingDetail'
    ? { companyId, userId }
    : requestId

  useEffect(() => {
    if (entityWithoutChannelContactDetail.userEmail) {
      const [user, domain] = entityWithoutChannelContactDetail?.userEmail.split('@')
      setEmailSplitted({ user, domain })
    }
  }, [caseTrackingEntityDetail, entityWithoutChannelContactDetail])

  fetchCaseTrackingEntityDetail(dataContext, requestObject, view, activeViews)
  return (
    <section id='CaseTrackingEntityDetail'>
      <div className='CaseTrackingEntityDetailTSide'>
        <div className='CaseTrackingEntityDetailIconBox'>
          <img
            src={view === 'caseTrackingDetail'
              ? caseTrackingEntityDetail.logo
              : entityWithoutChannelContactDetail.logo
            }
            alt={scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.altImageTxt3 : ''}
          />
        </div>
        <div className={`CaseTrackingEntityDetailInfo ${view === 'caseTrackingDetail' ? 'longWidth' : 'shortWidth'}`}>
          <div className='CaseTrackingEntityDetailInfoLSide'>
            <div className='CaseTrackingEntityDetailInfoHeader'>
              <h2 className='CaseTrackingEntityDetailTitle'>
                {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.title1 : ''}
              </h2>
              {activeViews.activeProcessListActive &&
                <div className='CaseTrackingEntityDetailBtnBox'>
                  <div className='CaseTrackingEntityDetailCurrentStatus'>
                    <p className='CaseTrackingEntityStateText'>{'Estado actual: '}</p>
                    <p className='CaseTrackingEntityStatus'>
                      {currentStatus === 1
                        ? 'Pendiente'
                        : currentStatus === 2
                          ? 'Finalizado'
                          : 'Rechazado'
                      }
                    </p>
                  </div>
                  <div className='CaseTrackingEntityDetailChangeStatus'>
                    <button className='CaseTrackingEntityDetailBtn' onClick={() => setActiveDropdown(!activeDropdown)}>
                      <img src={orangeArrowIcon} alt="orangeIcon" />
                    </button>
                    <p className='CaseTrackingEntityStateText'>{'(Cambiar estado)'}</p>
                    {activeDropdown &&
                      <div className='CaseTrackingEntityDetailStateOptions'>
                        {(currentStatus !== 1) &&
                          <button
                            onClick={() =>
                              handleChangeState(
                                1,
                                employeeId,
                                caseTrackingEntityDetail,
                                dataContext,
                                idToken,
                                setCurrentStatus,
                                setActiveDropdown
                              )}>
                            {'Pendiente'}
                          </button>
                        }
                        {(currentStatus !== 2) &&
                          <button
                            onClick={() =>
                              handleChangeState(
                                2,
                                employeeId,
                                caseTrackingEntityDetail,
                                dataContext,
                                idToken,
                                setCurrentStatus,
                                setActiveDropdown
                              )}>
                            {'Finalizado'}
                          </button>
                        }
                        {(currentStatus !== 3) &&
                          <button
                            onClick={() =>
                              handleChangeState(
                                3,
                                employeeId,
                                caseTrackingEntityDetail,
                                dataContext,
                                idToken,
                                setCurrentStatus,
                                setActiveDropdown
                              )}>
                            {'Rechazado'}
                          </button>
                        }
                      </div>
                    }
                  </div>
              </div>
              }
            </div>
            <div className={`CaseTrackingEntityDetailInfoContent ${view === 'caseTrackingDetail' ? 'longWidth' : 'shortWidth'}`}>
              <div className='CaseTrackingEntityDetailInfoContentLSide'>
                <p className='CaseTrackingEntityDetailText titleTextBold'>
                  {view === 'caseTrackingDetail'
                    ? caseTrackingEntityDetail.name
                    : entityWithoutChannelContactDetail.name
                  }
                </p>
                {view === 'caseTrackingDetail' &&
                  <p className='CaseTrackingEntityDetailText'>
                    {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.legalRepresentative : ''}
                    {caseTrackingEntityDetail?.legalName}
                  </p>
                }
                <p className='CaseTrackingEntityDetailText'>
                  {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.nit : ''}
                  {view === 'caseTrackingDetail'
                    ? caseTrackingEntityDetail.nit
                    : entityWithoutChannelContactDetail.nit
                  }
                </p>
              </div>
              <div className='CaseTrackingEntityDetailInfoContentRSide'>
                {view === 'caseTrackingDetail' &&
                  <>
                    <p className='CaseTrackingEntityDetailText'>
                      {caseTrackingEntityDetail.email}
                    </p>
                    <p className='CaseTrackingEntityDetailText'>
                      {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.phone : ''}
                      {`+57 ${caseTrackingEntityDetail.phone}`}
                    </p>
                    <p className='CaseTrackingEntityDetailText'>
                      {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.contact : ''}
                      {caseTrackingEntityDetail?.contactName}
                    </p>
                  </>
                }
                {view === 'noContact' &&
                  <p className='CaseTrackingEntityDetailText'>
                    {scriptsSave && scriptsSave.CaseTrackingEntityDetailString ? scriptsSave.CaseTrackingEntityDetailString.noContactChannel : ''}
                  </p>
                }
              </div>
            </div>
          </div>
          {view !== 'caseTrackingDetail' &&
            <div className='CaseTrackingEntityDetailInfoRSide'>
            <h2 className='CaseTrackingEntityDetailTitle'>
                {'Datos Usuario'}
              </h2>
              <div className='CaseTrackingEntityDetailInfoContentNoContact'>
                <div className='CaseTrackingEntityDetailInfoContentTSide'>
                  <p className='CaseTrackingEntityDetailText titleTextBold'>
                    {entityWithoutChannelContactDetail.userName}
                  </p>
                  <p className='CaseTrackingEntityDetailText'>
                    {`C.C: ${entityWithoutChannelContactDetail.userIdentNum}`}
                  </p>
                </div>
                <div className='CaseTrackingEntityDetailInfoContentBSide'>
                  <p className='CaseTrackingEntityDetailText'>
                    {'Correo: '}{emailSplitted.user}<br />
                    <div className='minimunLineHeight'>
                      {`@${emailSplitted.domain}`}
                    </div>
                  </p>
                  <p className='CaseTrackingEntityDetailText'>
                    {'Cel:'}<br />{entityWithoutChannelContactDetail.userPhone}
                  </p>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <ActiveProcessList
        isActive={activeViews.activeProcessListActive}
        setActiveViews={setActiveViews}
        demands={caseTrackingEntityDetail.demands}
        setDemandSelected={setDemandSelected}
      />
      <SelectProcess
        isActive={activeViews.selectProcess}
        requestData={{ userId, companyId, userIdentNum, companyNit: caseTrackingEntityDetail.nit }}
        demands={caseTrackingEntityDetail.demands}
        companyInfo={caseTrackingEntityDetail}
      />
      <DocumentationProcess
        isActive={activeViews.documentationProcess}
        idToken={idToken}
        setActiveViews={setActiveViews}
        demandSelected={demandSelected}
        requestData={{ userId, companyId }}
      />
      <UpdateProcess
        isActive={activeViews.updateProcess}
        demandInfo={demandSelected}
      />
      <SelectProcessNoContact
        isActive={activeViews.selectProcessNoContact}
        demandId={requestObject}
        entityWithoutChannelContactDetail={entityWithoutChannelContactDetail}
      />
    </section>
  )
}
