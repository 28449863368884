import { useEffect, useState } from 'react'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { getCaseTrackingUserDetail } from '../../../../utils/userQueries'

export const fetchCaseTrackingDetail = (dataContext, userId) => {
  const { dataState, dataDispatch } = dataContext
  const { idToken, caseTracking } = dataState.lawyer
  const { caseTrackingUserDetail } = caseTracking
  const [bodyData, setbodyData] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      const queryResponse = await getCaseTrackingUserDetail(dataContext, idToken, userId)

      if (queryResponse.response) {
        const data = queryResponse.body
        const { user, urls } = data
        const bodyUserData = {
          userId: user.userId,
          name: `${user.personId?.personName} ${user.personId?.personLastName}`,
          phone: user.personId?.personPhone,
          email: user.credentialId?.credentialEmail,
          identNum: user.personId?.personIdentNum,
          gender: user.personId?.genderId.genderId,
          city: user.personId?.personCity.cityName,
          createDt: user.createDt,
          demandList: user.demand,
          urls
        }
        setbodyData(bodyUserData)
      }
    }
    if (caseTrackingUserDetail.userId !== userId && userId) fetchData()

    fetchData()
  }, [])

  useEffect(() => {
    const action = {
      type: dataTypes.getCaseTrackingUserDetail,
      payload: {
        caseTrackingUserDetail: bodyData
      }
    }
    dataDispatch(action)
  }, [bodyData])
}
