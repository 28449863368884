import { handleDownload, textWithoutEspecials } from '../../../../../../utils/functions'
import { finishProcess, getDemandAnnexes, updateStartDate, uploadNewAnnex } from '../../../../../../utils/userQueries'

export const downloadAnnexes = async (dataContext, demandId, setDownloadAnnexesDisabled) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const responseReq = await getDemandAnnexes(dataContext, idToken, demandId)

  if (responseReq.response) {
    handleDownload(responseReq.body)
  } else {
    setDownloadAnnexesDisabled(true)
  }
}

export const uploadNewAnnexes = async (dataContext, demandId, file, setFile) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer

  const requestBody = {
    demandId,
    files: {
      value: 'demand',
      fileNames: [
        {
          fileName: textWithoutEspecials(file.name),
          contentType: file.type
        }
      ]
    }
  }

  const responseReq = await uploadNewAnnex(dataContext, idToken, requestBody)
  if (responseReq.response) {
    const keyName = Object.keys(responseReq.body)[0]
    const urlToSendFile = responseReq.body[textWithoutEspecials(keyName)].url
    await fetch(urlToSendFile, {
      method: 'PUT',
      body: file
    })
    setFile(null)
  }
}

export const handlefinishProcess = async (dataContext, statusIdNumber, handleShow, demandId, setRadicateNum, setIsRequesting, handleCloseRUSure) => {
  const { dataState } = dataContext
  const { idToken } = dataState.lawyer
  setIsRequesting(true)

  const requestBody = { statusId: statusIdNumber }
  const responseReq = await finishProcess(dataContext, idToken, requestBody, demandId)

  if (responseReq.response) {
    handleCloseRUSure()
    setRadicateNum(responseReq.body.radicatedNum)
    setIsRequesting(false)
    handleShow()
  }
}

export const handleNewStartDate = (e, setNewStartDate) => {
  const newValue = e.target.value
  setNewStartDate(newValue)
}

export const handleSaveNewDate = async (setCalendarActive, demandSelected, newStartDate, dataContext, idToken) => {
  setCalendarActive(false)
  const bodyRequest = {
    demandId: demandSelected.demandId,
    statusDate: newStartDate
  }
  await updateStartDate(dataContext, idToken, bodyRequest)
}

export const formatDateToDDMMYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-')
  return `${day}-${month}-${year}`
}
