import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../utils/functions'
import { getOneAdminChangePlan } from '../../utils/userQueries'

export const formatAdminChangePlanList = (adminChangePlanList, setAdminChangePlanList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(adminChangePlanList)
  const currentItem = page

  setAdminChangePlanList(adminChangePlanList.map((user, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      userId: user.userId,
      requestId: user.requestId,
      userName: user.personId ? `${user.personId.personName} ${user.personId.personLastName}` : '',
      identNum: user.personId ? user.personId.personIdentNum : '',
      currentPlan: user.planId ? (user.planId.planName === 'Plus' ? 'Más' : user.planId.planName) : ''
    }
  }))
}

export const onDetailClick = async (
  navigate,
  user,
  idToken,
  dataDispatch,
  dataContext
) => {
  const { response, data: body } = await getOneAdminChangePlan(idToken, dataContext, user.requestId)
  if (response) {
    let contentFormatted
    try {
      contentFormatted = JSON.parse(body.content)
    } catch (error) {
      if (typeof body.content === 'string') {
        contentFormatted = body.content.split('\n').reduce((acc, line) => {
          const [key, value] = line.split(':').map(item => item.trim())
          if (key && value) {
            acc[key] = isNaN(value) ? value : Number(value)
          }
          return acc
        }, {})
      }
    }
    const payload = {
      specificInfo: {
        planName: body.planName,
        name: `${body.personName} ${body.personLastName}`,
        phone: body.personPhone,
        idType: body.identDocName,
        idNum: body.personIdentNum,
        email: body.credentialEmail,
        personId: body.personId,
        userId: body.userId,
        city: body.cityName,
        gender: body.genderType,
        requestId: user.requestId
      },
      generalInfo: {
        creationDate: body.createDt
      },
      planToChangeInfo: contentFormatted,
      blockedCompanies: body.demand,
      authorizedCompanies: body.whiteList
    }
    const action = {
      type: dataTypes.setAdminChangePlanToBeReviewed,
      payload
    }
    dataDispatch(action)
    navigate('DetalleAdmCambioPlan')
  }
}
