import React, { useContext } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import noContactEntitiesIcon from '../../../../../assets/icons/icon_nocontactentity.svg'
import { ScriptContext } from '../../../../../context/ScriptsContext'

import './DocumentsUpdateLayout.css'
import { roles } from '../../../../../utils/objects'
import { DataContext } from '../../../../../context/DataContext'

export const DocumentsUpdateLayout = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { id } = useParams()
  const { dataState } = useContext(DataContext)
  const { role } = dataState.profile
  return (
    <section className='contactLayout'>
      <div className='headerContainerContact'>
        <div className='ContactAdminContainer'>
          <p className='ContactAdminParagraph'>
            {scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.documentsUpdate : ''}
          </p>
        </div>
        {role !== roles.ADMINISTRATIVO &&
          <Link
            className="entriesHeaderContainerButton"
            to={`../${id}/GestionUsuarios/EntidadesSinCanalDeContacto`}
          >
            <img
              alt={scriptsSave && scriptsSave.indexContact ? scriptsSave.indexContact.altImageTxt4 : ''}
              className="entriesHeaderContainerImg"
              src={noContactEntitiesIcon}
            />
          </Link>
        }
      </div>
      <hr className='hrContactAdmin' />
      <Outlet />
    </section>
  )
}
