import { Amplify } from './amplify'
import { textWithoutEspecials } from './functions'
import { CaseTrackingFindAll } from './mocks'
import { handleSessionFailures, uploadFilesToS3, uploadFilesToS3V2 } from './queries'
const amplify = new Amplify()

export const createPersonQuery = async (
  createPersonQueryBody,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/person/admin`, {
      method: 'POST',
      body: JSON.stringify(createPersonQueryBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updatePersonQuery = async (
  updatePersonQueryBody,
  personId,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/person/employee/${personId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(updatePersonQueryBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const createEmployeeQuery = async (
  createEmployeeQueryBody,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/employee`, {
      method: 'POST',
      body: JSON.stringify(createEmployeeQueryBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const createEmployeeCredentialQuery = async (
  createEmployeeCredentialQueryBody,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/employeeCredential`,
      {
        method: 'POST',
        body: JSON.stringify(createEmployeeCredentialQueryBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateEmployeeCredentialQuery = async (
  updateEmployeeCredentialQueryBody,
  employeeCredentialId,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/employeeCredential/${employeeCredentialId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(updateEmployeeCredentialQueryBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const deleteEmployeeCredentialQuery = async (
  employeeCredentialId,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/employeeCredential/${employeeCredentialId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getEmployeeCredentialQuery = async (idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/employeeCredential`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getOneEmployeeCredentialQuery = async (
  idToken,
  dataContext,
  employeeCredentialId
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/employeeCredential/${employeeCredentialId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getFormData = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response1 = await fetch(`${process.env.REACT_APP_HOST}/state`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const response2 = await fetch(`${process.env.REACT_APP_HOST}/city`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const response3 = await fetch(`${process.env.REACT_APP_HOST}/gender`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const response4 = await fetch(
      `${process.env.REACT_APP_HOST}/ident-document`,
      {
        headers: {
          TokenCatalogo: token
        }
      }
    )
    const response5 = await fetch(`${process.env.REACT_APP_HOST}/country`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const data1 = await response1.json()
    const data2 = await response2.json()
    const data3 = await response3.json()
    const data4 = await response4.json()
    const data5 = await response5.json()

    const formData = {
      states: data1.body,
      cities: data2.body,
      genders: data3.body,
      docTypes: data4.body,
      countries: data5.body
    }
    return formData
  } catch (error) {
    console.error(error)
  }
}

export const getShoppingHistory = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getShoppingInfo = async (dataContext, idToken, idDb) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/shoppingHistory/${idDb}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getRequirementTypesList = async (idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/requirement`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const data = response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getInstanceUpdatesTypesList = async (idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/instance-update`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const data = response.json()
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getAllUsersNotActive = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/credentials/findAll/UsersNotActive/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getUsersDeletingAccountData = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/users/Finish/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getActiveUsersData = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/find/authorized/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getOneUserData = async (
  idToken,
  dataContext,
  userId
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/find/authorized/${userId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getAdminChangePlanData = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/users/planAskings/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getOneAdminChangePlan = async (
  idToken,
  dataContext,
  requestId
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/users/planAskingsByUser/${requestId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const showCompanies = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/registry?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateBuyerData = async (
  dataContext,
  idToken,
  buyerId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyer/${buyerId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const patchDeleteUser = async (dataContext, id, queryBody, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/deleteUserData/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(queryBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const patchDeleteBuyer = async (dataContext, id, queryBody, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/auth/deleteBuyerData/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(queryBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getAllEntitiesNotActive = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyerCredential/buyer/active/validate/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getUserPocketMoves = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/reward/userWallet/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getUserPocketMovementHistory = async (
  dataContext,
  userId,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/reward/userWallet/${userId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPaymentOfEntitiesMovementHistory = async (
  dataContext,
  shoppingHistoryId,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/reward/payment-entities/${shoppingHistoryId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPaymentOfEntities = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/reward/payment-entities/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getTuDataControlPayments = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/allDemand/userControl?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getTuDataControlPaymentPerUser = async (
  dataContext,
  id,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/demandByCode/details/${id}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPackageInfo = async (dataContext, packageId, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/package/${packageId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const autorizeOrRejectPurchase = async (autorize, paymentId, dataContext, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const reqBody = {
      state: autorize,
      paymentCode: paymentId
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/payments-receipts-loopay/process-flow/operative`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const markEmailAsReaded = async (dataContext, id, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/documentFilter/read/${id}`,
      {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getUserNotActive = async (credentialId, idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/credentials/findOne/UsersNotActive/${credentialId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getBuyerNotActive = async (
  dataContext,
  buyerCredentialId,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyerCredential/findOne/buyer/active/validate/${buyerCredentialId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCompanyRegistrationInfo = async (
  dataContext,
  buyerId,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/companyRegistration/${buyerId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getpqrsOfUnregisteredUsers = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/notRegistry/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPaymentHistoryPerContactNotAllowed = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(`${process.env.REACT_APP_HOST}/reward/allReward/notAllowed/?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterPaymentHistoryPerContactNotAllowed = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/reward/allReward/notAllowed/search`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPaymentHistoryDetailsPerContactNotAllowed = async (
  dataContext,
  userId,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/reward/oneReward/notAlowed/${userId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPQRSList = async (
  idToken,
  dataContext,
  page = 1,
  limit = 20
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getPQRSDetail = async (dataContext, idToken, requestId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/${requestId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCaseTrackingList = async (idToken, dataContext, page = 1, limit = 20) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/demand/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    data.body.items.map((item) => {
      if (item.planId.planName === 'Plus') {
        item.planId.planName = 'Más'
      }
      return item
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCaseTrackingUserDetail = async (dataContext, idToken, userId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/demand/${userId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCaseTrackingEntityDetail = async (dataContext, idToken, requestData) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/demand/detail`,
      {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getEntityWithoutContactChannelList = async (idToken, dataContext, page = 1, limit = 20) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/withoutContact/?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getEntityWithoutContactChannelDetail = async (dataContext, idToken, requestId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/withoutContact/${requestId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const sendNewProcess = async (dataContext, idToken, requestData) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/newProcess`,
      {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const resendEmail = async ({ requestData, idToken, dataContext }) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/resend-logs/resendEmails`,
      {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const resendEmailEntityAuthorized = async ({ requestData, idToken, dataContext }) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/resend-logs/resendEmailsWhiteList`,
      {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const sendNewProcessWithoutContactChannel = async (dataContext, idToken, requestData, demandId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/withoutContact/${demandId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const sendNewInstance = async (dataContext, idToken, requestData, files) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/instance-history`,
      {
        method: 'POST',
        body: JSON.stringify(requestData),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    await uploadFilesToS3V2(data, files)
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getDemandAnnexes = async (dataContext, idToken, demandId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/download/${demandId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const uploadNewAnnex = async (dataContext, idToken, requestBody) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/upload`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const finishProcess = async (dataContext, idToken, statusId, demandId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/${demandId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(statusId),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getUserData = async (dataContext, idToken, userId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/id/${userId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getBuyerData = async (dataContext, idToken, buyerId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyer/${buyerId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getRequestActionUserData = async (dataContext, idToken, identNum) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/Person/lawyer/search/${identNum}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })

    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getAllCompanies = async (dataContext, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/company/lawyer`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })

    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCompanyData = async (dataContext, idToken, companyId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/company/lawyer/${companyId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })

    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getNotAllowedContactList = async (
  idToken,
  dataContext,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST
      }/request/findAll/notAllowed/?page=${page}&limit=${7}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getNotAllowedContactDetail = async (
  idToken,
  dataContext,
  requestId
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/findOneRequest/${requestId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getProceedNotAllowedContactData = async (
  idToken,
  userId,
  companyId,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const reqBody = { userId, companyId }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/contactNotAllowed`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const uploadDocumentPath = async (idToken, reqBody, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/documentPath`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const uploadDocumentFinishProcess = async (idToken, reqBody, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/end/uploadDocument`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const sendProceedContactNotAllowedResponse = async (
  idToken,
  reqBody,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/sendResponse`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const uploadDocumentUpdated = async (idToken, reqBody, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/updateBaseDocuments/`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updatePQRS = async (
  dataContext,
  idToken,
  requestId,
  pqrsResponseBody,
  pqrsId
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/${requestId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({ ...pqrsResponseBody, pqrsId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateUserEmail = async (
  dataContext,
  idToken,
  userId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/credentials/user/${userId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateUserData = async (
  dataContext,
  idToken,
  personId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/person/${personId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateFiles = async (
  dataContext,
  idToken,
  userId,
  requestBody,
  files
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/userDocument/${userId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    await uploadFilesToS3(data, files)
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateStartDate = async (
  dataContext,
  idToken,
  bodyRequest
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/lawyer/statusDate`,
      {
        method: 'PATCH',
        body: JSON.stringify(bodyRequest),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateCompanyState = async (
  dataContext,
  idToken,
  bodyRequest
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/global-status-process`,
      {
        method: 'PATCH',
        body: JSON.stringify(bodyRequest),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const createNewCompany = async (
  idToken,
  reqBody,
  createValuesFormatted,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/admin`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { file } = createValuesFormatted
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    const docProp = Object.keys(data.doc)[0]
    await fetch(data.doc[textWithoutEspecials(docProp)].url, {
      method: 'PUT',
      body: file
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const editCompany = async (
  id,
  idToken,
  reqBody,
  createValuesFormatted,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    delete reqBody.updateCompanyDto.companyId
    const response = await fetch(`${process.env.REACT_APP_HOST}/company/${id}`,
      {
        method: 'PATCH',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    if (createValuesFormatted?.file && typeof createValuesFormatted?.file !== 'string') {
      const { file } = createValuesFormatted
      const docProp = Object.keys(data.doc)[0]
      await fetch(data.doc[textWithoutEspecials(docProp)].url, {
        method: 'PUT',
        body: file
      })
    }
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateBuyerEmail = async (
  dataContext,
  idToken,
  buyerId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyerCredential/buyer/${buyerId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateBuyerPhone = async (
  dataContext,
  idToken,
  companyId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/phone/${companyId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const validateUserDocument = async (
  dataContext,
  idToken,
  userId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/validateDocument/${userId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const validateBuyerNit = async (
  dataContext,
  idToken,
  buyerId,
  requestBody
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/buyer/${buyerId}`,
      {
        method: 'PATCH',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getStates = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/state`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const data = await response.json()
    const dataList = data.body
    return dataList
  } catch (error) {
    console.error(error)
  }
}

export const getDocTypes = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/ident-document`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const data = await response.json()
    const dataList = data.body
    return dataList
  } catch (error) {
    console.error(error)
  }
}

export const getGenders = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/gender`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const data = await response.json()
    const dataList = data.body
    return dataList
  } catch (error) {
    console.error(error)
  }
}

export const getCities = async () => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/city`, {
      headers: {
        TokenCatalogo: token
      }
    })
    const data = await response.json()
    const dataList = data.body

    return dataList
  } catch (error) {
    console.error(error)
  }
}

export const getEmployeeEmailExists = async (dataContext, email, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/employeeCredential/validate/${email}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getEmployeePhoneOrIDExists = async (
  dataContext,
  value,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/person/search/${value}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getEmployeeIdExists = async (
  dataContext,
  value,
  idToken
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/person/employee/search/${value}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCompanyPhoneExistsValidation = async (idToken, reqBody, dataContext) => {
  const { companyNit, phone } = reqBody
  const requestBody = { companyNit, companyPhone: phone }
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/validate`,
      {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCompanyCredentialsExistsValidation = async (idToken, requestParam, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/search/${requestParam}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCompaniesToMassiveUploads = async (dataContext, idToken) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/findAll/sponsor`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateControlPaymentDemand = async (
  dataContext,
  idToken,
  bodyQuery
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/responseDemand/control`,
      {
        method: 'POST',
        body: JSON.stringify(bodyQuery),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getResponsesByEntity = async (idToken, dataContext, page = 1, limit = 20) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(`${process.env.REACT_APP_HOST}/filter-emails/documentFilter/findAll/?page=${page}&limit=${limit}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })

    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getOneResponseByEntity = async (idToken, dataContext, emailId) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/filter-emails/documentFilter/${emailId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getAnnexesInresponsesByEntity = async (
  idToken,
  idUser,
  idCompany,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    // const reqBody = { idUser: '8E8B433E-F36B-1410-8B59-0033A15C6EE1', idCompany: 'DC80433E-F36B-1410-8B52-0033A15C6EE1' }
    const reqBody = { idUser, idCompany }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/download/attachments/`,
      {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const setEmailToRead = async (emailId, idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/documentFilter/read/${emailId}`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}

export const pushEntityResponseDocument = async (file, idUser, idCompany, employeeId, idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const body = {
      idUser,
      idCompany,
      employeeId,
      namefiles: textWithoutEspecials(file.name),
      contentType: file.type
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/uploadResponse/`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    const docProp = Object.keys(data.body.doc)[0]
    await fetch(data.body.doc[textWithoutEspecials(docProp)].url, {
      method: 'PUT',
      body: file
    })
  } catch (error) {
    console.error(error)
  }
}

export const pushCreatedDocument = async (file, userId, companyId, employeeId, idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const body = {
      userId,
      companyId,
      employeeId,
      namefiles: textWithoutEspecials(file.name),
      contentType: file.type
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/uploadCreatedDoc/`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    const docProp = Object.keys(data.body.doc)[0]
    await fetch(data.body.doc[textWithoutEspecials(docProp)].url, {
      method: 'PUT',
      body: file
    })
    return data
  } catch (error) {
    console.error(error)
  }
}

export const sendRecursoReposicionEmail = async (userId, companyId, idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const body = {
      idUser: userId,
      idCompany: companyId
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/filter-emails/uploadRecurso`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const getCaseTracking = async (idToken, dataContext, page = 1, limit = 20) => {
  try {
    const response = CaseTrackingFindAll
    return response
  } catch (error) {
    console.error(error)
  }
}

export const validateUserHasDemandsWithAnEntity = async (idUser, idCompany, idToken, dataContext) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(`${process.env.REACT_APP_HOST}/filter-emails/validateDemand`, {
      method: 'POST',
      body: JSON.stringify({
        idUser,
        idCompany
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
        TokenCatalogo: token
      }
    })
    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterUsersAdmin = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterAdminChangePlan = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/users/planAskings/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterCaseTracking = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/demand/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterCompanies = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/company/registry/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterDeletionRequests = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/request/users/finish/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const searchFilterEntitiesWithoutContact = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/demand/users/withoutContact/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}
export const searchFilterUserPocket = async (
  idToken,
  dataContext,
  queryBody,
  page = 1,
  limit = 7
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    if (page === 0) page = 1
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/reward/userWallet/search?page=${page}&limit=${limit}`,
      {
        method: 'POST',
        body: JSON.stringify(queryBody),
        headers: {
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token,
          'Content-Type': 'application/json'
        }
      }
    )
    const { navigate, dataDispatch, handleShowInactiveSessionModal } = dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )
    return data
  } catch (error) {
    console.error(error)
  }
}

export const updateUserPlan = async (
  plan,
  politicsAccepted,
  userId,
  idToken,
  dataContext
) => {
  try {
    let token = await amplify.getToken()
    const isToken = amplify.validateToken(token)
    if (!isToken) {
      amplify.cleanToken()
      await amplify.sign()
      token = await amplify.getToken()
    }
    const response = await fetch(
      `${process.env.REACT_APP_HOST}/user/${userId}`,
      {
        method: 'PATCH',
        body: JSON.stringify({
          planId: plan,
          politicsAccepted
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
          TokenCatalogo: token
        }
      }
    )

    const { navigate, dataDispatch, handleShowInactiveSessionModal } =
      dataContext
    const data = await handleSessionFailures(
      response,
      navigate,
      dataDispatch,
      handleShowInactiveSessionModal
    )

    return data
  } catch (error) {
    console.error(error)
  }
}
