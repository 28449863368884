import { dataTypes } from '../../context/reducers/dataReducer/dataTypes'
import { calculateNumberOfDigits } from '../../utils/functions'
import { getOneUserData } from '../../utils/userQueries'

export const onDetailClick = async (
  navigate,
  user,
  idToken,
  dataDispatch,
  dataContext
) => {
  const { response, body } = await getOneUserData(idToken, dataContext, user.userId)
  if (response) {
    const payload = {
      specificInfo: {
        planName: body.user.planName,
        personActive: body.user.personActive,
        name: `${body.user.personName} ${body.user.personLastName}`,
        idType: body.user.identDocName,
        phone: body.user.personPhone,
        idNum: body.user.personIdentNUm,
        email: body.user.credentialEmail,
        city: body.user.cityName,
        gender: body.user.genderType,
        personId: user.personId,
        credentialId: body.user.credentialId
      },
      generalInfo: {
        creationDate: body.user.createDt,
        actualBalance: body.total
      },
      blockedCompanies: body.user.demand,
      authorizedCompanies: body.user.whiteList
    }

    const action = {
      type: dataTypes.setUserManagementToBeReviewed,
      payload
    }

    dataDispatch(action)
    navigate('DetalleUsuario')
  }
}

export const formatUsersManagementList = (usersManagementList, setUsersManagementList, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(usersManagementList)
  const currentItem = page

  setUsersManagementList(usersManagementList.map((user, index) => {
    let id = (currentItem * limit) - limit + 1
    if (id < 1) id = 1
    return {
      id: String(id + index).padStart(numberOfDigits, '0'),
      buyerId: user.buyerId,
      userId: user.userId,
      nameInList: user?.buyerId ? user?.companyId?.companyComertialName : `${user.personId.personName} ${user.personId.personLastName}`,
      identNumInList: user?.buyerId ? user?.companyId?.companyNit : user.personId.personIdentNum,
      userType: user?.buyerId ? 'Usuario Empresa' : 'Usuario Persona',
      personId: user.personId.personId,
      credentialId: user?.credentialId?.credentialId
    }
  }))
}
