import React from 'react'
import { calculateNumberOfDigits } from '../../../../utils/functions'
import userPersonImg from '../../../../assets/images/userPerson.png'
import userCompanyImg from '../../../../assets/images/userCompany.png'

export const formatPqrsList = (pqrsList, setPqrsListFormatted, page, limit) => {
  const numberOfDigits = calculateNumberOfDigits(pqrsList)
  const currentItem = page

  setPqrsListFormatted(
    pqrsList.map((obj, index) => {
      let id = currentItem * limit - limit + 1
      if (id < 1) id = 1
      return {
        id: String(id + index).padStart(numberOfDigits, '0'),
        name: obj.userId ? `${obj.userId?.personId?.personName} ${obj.userId?.personId?.personLastName}` : obj.buyerId.companyId.companyComertialName,
        pqrs: obj.pqrsId?.pqrsName,
        user: <img src={obj.userId ? userPersonImg : userCompanyImg} className='userTypeIcon' alt="userTypeIcon" />,
        userType: obj.userId ? 'Usuario Persona' : 'Usuario Empresa',
        requestId: obj.requestId,
        isUser: !!obj.userId
      }
    })
  )
}
