import {
  patchDeleteBuyer,
  patchDeleteUser,
  updateBuyerData,
  updatePQRS,
  updateUserData
} from '../../../../utils/userQueries'
import { handleRequestButton, hasRepeatedCharactersAtMiddle, validateNoWhiteSpacesAtBeginning } from '../../../../utils/validateFunctions'

export const handleClick1 = (handleShow, setSelectedModal) => {
  handleShow()
  setSelectedModal('inactivar')
}

export const handleClick2 = (handleShow, setSelectedModal) => {
  handleShow()
  setSelectedModal('eliminar')
}

export const validateTextarea = (e, setState) => {
  const text = e.target.value

  if (text.length > 1800) return

  if (validateNoWhiteSpacesAtBeginning(text)) {
    if (hasRepeatedCharactersAtMiddle(text)) {
      return ''
    }
    setState(text)
  }
}

export const onConfirmDisablementClick = async (
  userManagementToBeReviewed,
  handleClose,
  navigate,
  idToken,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  const { buyerId, personId, requestId } = userManagementToBeReviewed

  const { dataState } = dataContext
  const { employeeId } = dataState.operative

  handleRequestButton(setIsRequesting)
  let response
  if (personId) {
    const updateUserDataResponse = await updateUserData(dataContext, idToken, personId, { personActive: false })
    response = updateUserDataResponse.response
    updatePQRS(dataContext, idToken, requestId, { employeeId, statusId: 2 })
  }

  if (buyerId) {
    const updateBuyerDataResponse = await updateBuyerData(dataContext, idToken, buyerId, { buyerActive: false })
    response = updateBuyerDataResponse.response
    updatePQRS(dataContext, idToken, requestId, { employeeId, statusId: 2 })
  }

  if (response) {
    handleClose()
    navigate('../')
  }
}

export const onConfirmDeletionClick = async (
  userManagementToBeReviewed,
  handleClose,
  navigate,
  idToken,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  const { buyerCredentialId, credentialId, email, requestId } = userManagementToBeReviewed
  const queryBody = { email }

  const { dataState } = dataContext
  const { employeeId } = dataState.operative

  handleRequestButton(setIsRequesting)
  let response
  if (credentialId) {
    const patchDeleteUserResponse = await patchDeleteUser(dataContext, credentialId, queryBody, idToken)
    response = patchDeleteUserResponse.response
    updatePQRS(dataContext, idToken, requestId, { employeeId, statusId: 2 })
  }
  if (buyerCredentialId) {
    const patchDeleteBuyerResponse = await patchDeleteBuyer(dataContext, buyerCredentialId, queryBody, idToken)
    response = patchDeleteBuyerResponse.response
    updatePQRS(dataContext, idToken, requestId, { employeeId, statusId: 2 })
  }

  if (response) {
    handleClose()
    navigate('../')
  }
}

export const onRejectDeletionClick = async (
  userManagementToBeReviewed,
  handleShowReject,
  navigate,
  idToken,
  dataContext,
  isRequesting,
  setIsRequesting
) => {
  if (isRequesting) return

  const { requestId } = userManagementToBeReviewed

  const { dataState } = dataContext
  const { employeeId } = dataState.operative

  handleRequestButton(setIsRequesting)
  const response = await updatePQRS(dataContext, idToken, requestId, { employeeId, statusId: 3 })

  if (response) {
    handleShowReject()
  }
}
