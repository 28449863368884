import React, { useContext } from 'react'
import { Modal } from '../Modal/Modal'
import { ScriptContext } from '../../context/ScriptsContext'

export const AreYourSureModal = ({ showModal, handleClose, functionToRun, isRequesting }) => {
  const { scriptsSave } = useContext(ScriptContext)
  return (
    <Modal isOpen={showModal}>
        <Modal.Content>
            <p>{'¿Estás seguro de realizar esta acción?'}</p>
        </Modal.Content>
        <Modal.Buttons>
            <button onClick={handleClose} className='Modal-normalBtn'>
                {scriptsSave && scriptsSave.compString ? scriptsSave.compString.cancel : ''}
            </button>
            <button disabled={isRequesting} onClick={functionToRun} className='Modal-orangeBtn'>
                {scriptsSave && scriptsSave.requestsString ? scriptsSave.requestsString.accept : ''}
            </button>
        </Modal.Buttons>
    </Modal>
  )
}
