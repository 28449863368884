import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { DataContext } from '../../../../context/DataContext'
import backIcon from '../../../../assets/icons/icon_volver.svg'
import { formatDateDDMMYYMonthInLetters } from '../../../../utils/functions'
import { ScriptContext } from '../../../../context/ScriptsContext'

import './UserMovements.css'

export const UserMovements = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const { dataState } = useContext(DataContext)
  const { userPocketToBeReviewed } = dataState.operative.movements
  const { movementHystory } = userPocketToBeReviewed

  return (
    <section id='UserMovements'>
      <div className='UserMovements-content'>
        <div className='UserMovements-content-lside'>
          <div className='UserMovements-content-lside-data'>
            <h2>{scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.data : ''}</h2>
            <div>
              <p className='UserMovements-content-lside-key'>
                {scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.name : ''}
              </p>
              <p className='UserMovements-content-lside-value'>{userPocketToBeReviewed.name}</p>
            </div>
            <div>
              <p className='UserMovements-content-lside-key'>
                {scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.email : ''}
              </p>
              <p className='UserMovements-content-lside-value'>{userPocketToBeReviewed.email}</p>
            </div>
          </div>
        </div>
        <div className='UserMovements-content-rside'>
          <h2>{scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.historyMovements : ''}</h2>
          <div className="UserMovements-TableContainer">
            <table>
              <thead>
                <tr>
                  <th>{scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.thead1 : ''}</th>
                  <th>{scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.thead2 : ''}</th>
                  <th>{scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.thead3 : ''}</th>
                </tr>
              </thead>
              <tbody>
                {
                  movementHystory[0]?.map((movement, index) => (
                    <tr key={index}>
                      <td>{movement.transactionType}</td>
                      <td>{formatDateDDMMYYMonthInLetters(movement.date)}</td>
                      <td>{movement.amount}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='UserMovements-footer'>
        <div>
          <Link to={'..'} className='entityMovements-content-footer-backLink'>
            <img
              src={backIcon}
              alt={scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.altImageTxt1 : ''}
            />
            <span>{scriptsSave && scriptsSave.userMovementsString ? scriptsSave.userMovementsString.back : ''}</span>
          </Link>
        </div>
      </div>
    </section>
  )
}
