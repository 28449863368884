import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataContext } from '../../../../context/DataContext'
import { usePaginateQuery } from '../../../../hooks/usePaginateQuery'
import { getPaymentOfEntities } from '../../../../utils/userQueries'
import { dataTypes } from '../../../../context/reducers/dataReducer/dataTypes'
import { formatPaymentOfEntitiesList, onDetailsClick } from './PagoEntidadesFunctions'
import { useFilterBySearch } from '../../../../hooks/useFilterBySearch'
import { Dropdown } from '../../../../components/Dropdown/Dropdown'
import { Pagination } from '../../../../components/Pagination/Pagination'
import inhabilitarIcon from '../../../../assets/icons/icon_inhabilitar_usuario.svg'
import editIcon from '../../../../assets/icons/icon_editar.svg'
import { ScriptContext } from '../../../../context/ScriptsContext'
import './PagoEntidades.css'

export const PagoEntidades = () => {
  const { scriptsSave } = useContext(ScriptContext)
  const dataContext = useContext(DataContext)
  const { dataState, dataDispatch } = dataContext
  const { idToken, movements } = dataState.operative
  const { paymentOfEntities } = movements
  const paymentOfEntitiesList = movements.paymentOfEntities.list

  const [paginationValues, setPaginationValues] = usePaginateQuery(
    paymentOfEntities,
    'paymentOfEntities',
    getPaymentOfEntities,
    dataTypes.updatePaymentOfEntities,
    idToken,
    dataContext
  )

  const { page, limit, totalPages } = paginationValues

  const { originalList, listFormatted, setListFormatted } = useFilterBySearch(
    paymentOfEntitiesList,
    formatPaymentOfEntitiesList,
    page,
    limit
  )

  const navigate = useNavigate()

  return (
    <section id='PagoEntidades'>
      <table>
        <thead>
          <tr>
            <th>
              {scriptsSave && scriptsSave.payEntitiesString ? scriptsSave.payEntitiesString.thead1 : ''}
            </th>
            <th>
              <Dropdown
                filterBy={'entityName'}
                list={listFormatted}
                sortType={'string'}
                title={scriptsSave && scriptsSave.payEntitiesString ? scriptsSave.payEntitiesString.thead2 : ''}
                type={'orderSelect'}
                updateList={setListFormatted}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'nit'}
                list={originalList}
                sortType={'number'}
                title={scriptsSave && scriptsSave.payEntitiesString ? scriptsSave.payEntitiesString.thead3 : ''}
                type={'orderSelect'}
                updateList={setListFormatted}
              />
            </th>
            <th>
              <Dropdown
                filterBy={'nit'}
                list={originalList}
                title={scriptsSave && scriptsSave.payEntitiesString ? scriptsSave.payEntitiesString.thead7 : ''}
                // type={'search'}
                updateList={setListFormatted}
              />
            </th>
            <th>{scriptsSave && scriptsSave.payEntitiesString ? scriptsSave.payEntitiesString.thead6 : ''}</th>
          </tr>
        </thead>
        <tbody>
          {
            listFormatted.map(payment => (
              <tr key={payment.id}>
                <td>{payment.id}</td>
                <td>{payment.entityName}</td>
                <td>{payment.nit}</td>
                <td><img src={inhabilitarIcon} className='image-icon'/></td>
                <td>
                  <button className='companiesAdminDetailBtn'
                    onClick={() => onDetailsClick(
                      navigate,
                      payment.shoppingHistoryId,
                      idToken,
                      dataDispatch,
                      dataContext
                    )}
                  >
                    <img
                      src={editIcon}
                      alt={scriptsSave && scriptsSave.payEntitiesString ? scriptsSave.payEntitiesString.altImageTxt1 : ''}
                      className='image-icon'
                    />
                  </button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Pagination
        currentPage={paymentOfEntities.currentPage}
        paginationValues={paginationValues}
        scrollMeasurement={paymentOfEntities.scrollMeasurement}
        setPaginationValues={setPaginationValues}
        totalPages={totalPages}
      />
    </section>
  )
}
